<template>
    <client-only>
        <div v-if="startDate < new Date()"
            :id="properties.blok._uid"
            :class="{
                'flex flex-col items-center text-center': centered
            }">
            <div v-if="properties.blok.title">
                <p class="mb-xs text-2xl font-bold uppercase">
                    {{ properties.blok.title }}
                </p>

                <sb-rich-text :document="properties.blok.ingress" />
            </div>

            <div class="flex flex-row gap-[2px] text-center text-6xl md:gap-xs"
                :style="{
                    '--background-color': blok.background_color?.color,
                    '--color': blok.text_color?.color,
                }">
                <div class="rounded border border-gray-100 bg-[color:var(--background-color)]
                    p-4 text-center text-[color:var(--color)]"
                    :class="{
                        'w-20 md:w-28': inCta,
                        'w-[90px] md:w-36': !inCta
                    }">
                    <v-h2 tag="p"
                        class="font-bold">
                        {{ remaining.days }}
                    </v-h2>

                    <p class="text-xs">
                        {{ t('promotion-countdown.days') }}
                    </p>
                </div>

                <div class="rounded border border-gray-100
                    bg-[color:var(--background-color)] p-4 text-[color:var(--color)]"
                    :class="{
                        'w-20 md:w-28': inCta,
                        'w-[90px] md:w-36': !inCta
                    }">
                    <v-h2 tag="p"
                        class="font-bold">
                        {{ remaining.hours }}
                    </v-h2>

                    <p class="text-xs">
                        {{ t('promotion-countdown.hours') }}
                    </p>
                </div>

                <div class="rounded border border-gray-100
                    bg-[color:var(--background-color)] p-4 text-[color:var(--color)]"
                    :class="{
                        'w-20 md:w-28': inCta,
                        'w-[90px] md:w-36': !inCta
                    }">
                    <v-h2 tag="p"
                        class="font-bold">
                        {{ remaining.minutes }}
                    </v-h2>

                    <p class="text-xs">
                        {{ t('promotion-countdown.minutes') }}
                    </p>
                </div>

                <div class="rounded border border-gray-100
                    bg-[color:var(--background-color)] p-4 text-[color:var(--color)]"
                    :class="{
                        'w-20 md:w-28': inCta,
                        'w-[90px] md:w-36': !inCta
                    }">
                    <v-h2 tag="p"
                        class="font-bold">
                        {{ remaining.seconds }}
                    </v-h2>

                    <p class="text-xs">
                        {{ t('promotion-countdown.seconds') }}
                    </p>
                </div>
            </div>
        </div>
    </client-only>
</template>

<script setup lang="ts">
import type { BlokComponent } from '~/types';

// Props
const properties = defineProps<{
    blok: Extract<BlokComponent, { component: 'sb-promotion-countdown' }>
    inCta?: boolean
}>();

const { t } = useI18n();

interface CountdownValues {
    days: number
    hours: number
    minutes: number
    seconds: number
}

const startDate = computed(() => new Date(properties.blok.start_date));
const endDate = computed(() => new Date(properties.blok.end_date));
const centered = computed(() => properties.blok.centered_content);

const currentTimeDefault = Date.now();
const targetTimeDefault = endDate.value.getTime();
const timeRemainingDefault = targetTimeDefault - currentTimeDefault;

const remaining = ref<CountdownValues>({
    days: Math.floor(timeRemainingDefault / (1000 * 60 * 60 * 24)),
    hours: Math.floor((timeRemainingDefault % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
    minutes: Math.floor((timeRemainingDefault % (1000 * 60 * 60)) / (1000 * 60)),
    seconds: Math.floor((timeRemainingDefault % (1000 * 60)) / 1000),
});

// Update countdown values
const updateCountdown = () => {
    const currentTime = Date.now();
    const targetTime = endDate.value.getTime();
    const timeRemaining = targetTime - currentTime;

    if (timeRemaining > 0) {
        remaining.value.days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
        remaining.value.hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        remaining.value.minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
        remaining.value.seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);
    } else {
        // If the activeTo date has already passed, display a message
        remaining.value.days = 0;
        remaining.value.hours = 0;
        remaining.value.minutes = 0;
        remaining.value.seconds = 0;
    }
};

// Update countdown initially and watch for changes in activeTo
onMounted(() => {
    updateCountdown();

    // Update countdown every second
    const intervalId = setInterval(updateCountdown, 1000);

    // Clear the interval when the component is unmounted
    onUnmounted(() => {
        clearInterval(intervalId);
    });
});
</script>
